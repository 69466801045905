import {
  doc, getDoc, getFirestore, updateDoc,
} from 'firebase/firestore';
import { Collections, Response } from '@/constants';
import {
  AssignedBuyers, BuyerDetails, Homebuyer, IPartialHomeBuyer,
} from '@/models';
import { checkStringEquality } from '../util/funcs';
import { addAdditonalBuyer } from '@/api';

const updateFirestoreRecord = async (collectionName: string, uid: string, body: any): Promise<string> => {
  try {
    const firestore = getFirestore();
    const receiptRef = doc(firestore, `${collectionName}/${uid}`);
    await updateDoc(receiptRef, body);
    return Response.SUCCESS;
  } catch (err) {
    return Response.ERROR;
  }
};

const updateFirestoreRecordField = async (collectionName: string, docId: string, field: string, content: any): Promise<string> => {
  try {
    const firestore = getFirestore();
    const receiptRef = doc(firestore, `${collectionName}/${docId}`);
    await updateDoc(receiptRef, field, content);
    return Response.SUCCESS;
  } catch (err) {
    return Response.ERROR;
  }
};

const getSnapshotWithReferenceId = (collectionName: string, id: string) => {
  const firestore = getFirestore();
  const receiptRef = doc(firestore, `${collectionName}/${id}`);
  return receiptRef;
};

export const filterLoggedInBuyer = (homebuyers: AssignedBuyers[], homeBuyerId: string) => homebuyers?.filter((buyer: any) => !checkStringEquality(buyer.homeBuyerId, homeBuyerId)) || [];

const getUserInfo = async (filteredBuyers: AssignedBuyers[]) => {
  let users = [] as (BuyerDetails | undefined)[];
  users = await Promise.all(filteredBuyers.map(async (buyer: any) => {
    const receiptRef = getSnapshotWithReferenceId(Collections.HOMEBUYERS, buyer.homeBuyerId);
    const snapshot = await getDoc(receiptRef);
    if (snapshot.exists()) {
      const userData = Homebuyer.fromFirestore(snapshot);
      return { ...userData, type: buyer.type };
    }
  }));
  return users.filter((user) => !!user) as BuyerDetails[];
};

const addAdditionalUser = async (homeId: string, user: IPartialHomeBuyer, communityId: string, divisionId: string) => addAdditonalBuyer({
  homeId, communityId, divisionId, user,
});

export {
  updateFirestoreRecord, getUserInfo, addAdditionalUser, updateFirestoreRecordField, getSnapshotWithReferenceId,
};

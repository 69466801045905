import {
  getAuth, updatePassword, EmailAuthProvider, reauthenticateWithCredential, User, EmailAuthCredential,
} from 'firebase/auth';
import {
  Collections,
  HomeBuyer, Response, ValidationMessages,
} from '@/constants';
import { checkRequireField, isNameValid, isValidPhoneNumber } from '@/util/validation';
import { updateUserStatus, triggerResetPasswordMail } from '@/api';
import { BuyerDetails } from '@/models';
import { checkStringEquality } from '@/util/funcs';
import { updateFirestoreRecordField } from '@/core/user';

const changePassword = async (newPassword: string, user: User) => updatePassword(user, newPassword)
  .then(() => triggerResetPasswordMail({ email: user.email?.toLowerCase() }));

const reAuthenticate = (user: User, newPassword: string, credential: EmailAuthCredential) => reauthenticateWithCredential(user, credential)
  .then(() => changePassword(newPassword, user));

const reAuthenticateAndUpdate = (user: User, email: string, currentPassword: string, newPassword: string) => {
  const credential = EmailAuthProvider.credential(
    email,
    currentPassword,
  );
  return reAuthenticate(user, newPassword, credential);
};

const handlePasswordSet = async (updateEmailVerified: any, showModal: any) => {
  const user = getAuth()?.currentUser;
  if (user && !user?.emailVerified) {
    await updateUserStatus({
      email: user.email?.toLowerCase(),
      status: true,
    }).then(() => {
      showModal(Response.SUCCESS);
      updateEmailVerified(user);
    }).catch((err) => {
      showModal(Response.ERROR);
    });
  } else {
    showModal(Response.SUCCESS);
  }
};

let validationError: any = {};

const isEmptyField = (data: string, fieldName: string) => {
  const message = checkRequireField(data, fieldName);
  if (message) {
    validationError[fieldName.split(' ').join('').toLowerCase()] = message;
  }
};

const updateBuyerVerification = async (email: string) => updateFirestoreRecordField(Collections.HOMEBUYERS, email.toLowerCase(), 'userVerified', true);

const isValidString = (data: string, fieldName: string, minLength: number) => {
  const message = isNameValid(data, fieldName, minLength);
  if (message) {
    validationError[fieldName.split(' ').join('').toLowerCase()] = message;
  }
};

const validatePhoneNumber = (phoneNumber: string, fieldName: string) => {
  if (!phoneNumber) {
    validationError[fieldName] = ValidationMessages.PhoneNumberRequiredError;
  } else {
    const isValid = isValidPhoneNumber(phoneNumber);
    if (!isValid) {
      validationError[fieldName] = ValidationMessages.InvalidPhoneNumberError;
    }
  }
};

const validateContactData = (firstName: string, lastName: string, mobilephone: string, homePhone = '', workPhone = '') => {
  isEmptyField(firstName, 'First Name');
  isValidString(firstName, 'First Name', 1);
  isEmptyField(lastName, 'Last Name');
  isValidString(lastName, 'Last Name', 2);
  if (homePhone) validatePhoneNumber(homePhone, 'homePhone');
  if (workPhone) validatePhoneNumber(workPhone, 'workphone');
  validatePhoneNumber(mobilephone, 'mobilePhone');
  const errors = validationError;
  validationError = {};
  return errors;
};

const isDuplicateEntry = (obj1: any, obj2: any) => (obj1.email as string).toLowerCase() === (obj2.email as string).toLowerCase();

const containsObject = (obj: any, list: any) => {
  for (let idx = 0; idx < list.length; idx += 1) {
    const isEqual = isDuplicateEntry(obj, list[idx]);
    if (isEqual) {
      return true;
    }
  }
  return false;
};

const sortBuyers = (buyers: BuyerDetails[], order: string[]) => {
  let orderedEntries: BuyerDetails[] = [];
  order.forEach((entry) => {
    orderedEntries = [...orderedEntries, ...buyers.filter((buyer) => checkStringEquality(buyer.type, entry))];
  });
  return orderedEntries;
};

export {
  validateContactData,
  reAuthenticateAndUpdate,
  handlePasswordSet,
  containsObject,
  sortBuyers,
  updateBuyerVerification,
};
